import { render, staticRenderFns } from "./CruAks.vue?vue&type=template&id=1200b4cc&scoped=true&"
import script from "./CruAks.vue?vue&type=script&lang=ts&"
export * from "./CruAks.vue?vue&type=script&lang=ts&"
import style0 from "./CruAks.vue?vue&type=style&index=0&id=1200b4cc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1200b4cc",
  null
  
)

export default component.exports